import {TFunction} from 'i18next';
import amazon_logo from 'images/amazon_logo.svg';

export const getProducts = (t: TFunction) => [
  {
    name: 'Ecommerce Marketplaces',
    description: t(
      'microapps management of all operations for pet, furniture, pharma, kitchen / garden & games verticals within multiple marketplaces like Amazon Vendor Central, Cdiscount, Carrefour, El Corte Inglés, Alibaba, Zalando, Glovo, PcComponentes, ManoMano, MediaMarkt... Processing more than 3 M € gross sales per month.'
    ),
    logo: amazon_logo,
    color: '#F3F6F3',
    url: '/ecommerce-marketplaces'
  }
];
