// i18next-extract-mark-ns-start index

import {Link} from 'components/Link';
import {customers} from 'data/customers';
import {getProducts} from 'data/products';
import {graphql} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import bg1 from 'images/bg1.svg';
import bg2 from 'images/bg2.svg';
import bg3 from 'images/bg3.svg';
import bg4 from 'images/bg4.svg';
import React from 'react';

const Index: React.FC = () => {
  const {t} = useI18next();
  const products = getProducts(t);

  return (
    <div className="relative pt-20 md:pt-52 overflow-y-hidden">
      <img className="absolute top-0 right-0 z-0 w-10/12 md:w-1/2" src={bg1} alt="background 1" />
      <img className="absolute top-96 md:top-56 left-0 z-0 md:w-1/3" src={bg2} alt="background 2" />
      <img className="absolute right-0 top-1/2 z-0" src={bg4} alt="background 4" />
      <div className="container px-6 py-7 md:py-14 relative z-10">
        <section className="flex flex-wrap md:flex-nowrap flex-col-reverse md:flex-row overflow-hidden md:overflow-visible">
          <div className="md:ml-16">
            <h1 className="max-w-5xl leading-snug uppercase font-bold text-gray-700 text-3xl md:text-5xl tracking-wide md:leading-snug mb-5">
              <Trans>Development of Ecommerce Ecosystems</Trans>
            </h1>
            <div className="max-w-lg">
              <p className="leading-normal mb-6">
                <Trans>
                  We are an engineering company with a worldwide distributed team across more than 5
                  countries.
                </Trans>
              </p>
              <p className="leading-normal mb-6">
                <Trans>
                  We help large companies succeed in their ecommerce journey by applying a
                  scientific approach.
                </Trans>
              </p>
            </div>
            <a href="mailto:hi@microapps.com" className="btn">
              <Trans>Contact us</Trans>
            </a>
          </div>
          <div className="-mx-14 md:-mr-28 md:-ml-6 md:-mt-36 md:-mb-16 relative z-10">
            <StaticImage
              src="../images/main.png"
              width={1800}
              placeholder="none"
              className="md:max-w-none"
              alt="Ecommerce Scientific Engineering"
            />
          </div>
        </section>
        <img className="absolute z-0 left-0 right-0 m-auto" src={bg3} alt="background 3" />
        <section className="mb-32 relative z-10 pt-28">
          <h2 className="uppercase text-2xl text-gray-700 font-medium tracking-wide leading-snug mb-5 text-center">
            <Trans>Our products</Trans>
          </h2>
          <div className="flex flex-wrap lg:-mx-2 justify-center">
            {products.map((product, i) => (
              <div className="py-4 md:p-4 xl:p-2 xl:w-1/2 lg:w-1/2" key={i}>
                <div className="h-full bg-white shadow-md overflow-hidden rounded-lg flex flex-col">
                  <div
                    className="h-56 flex justify-center align-middle bg-gray-100"
                    style={{background: product.color}}>
                    <img src={product.logo} alt="blog" className="block m-auto" width={250} />
                  </div>
                  <div className="p-6 flex flex-col flex-1">
                    <h3 className="text-2xl font-medium text-gray-900 mb-3">{product.name}</h3>
                    <p className="leading-relaxed mb-3 flex-1">{product.description}</p>
                    <div>
                      <Link
                        className="text-blue-500 inline-flex items-center hover:text-blue-600 font-medium"
                        to={product.url}
                        target="_blank"
                        rel="noopener noreferrer">
                        <Trans>Learn More</Trans>
                        <svg
                          className="w-4 h-4 ml-2"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round">
                          <path d="M5 12h14" />
                          <path d="M12 5l7 7-7 7" />
                        </svg>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
        <section className="mb-16">
          <h2 className="uppercase text-2xl text-gray-700 font-medium tracking-wide leading-snug mb-5 text-center">
            <Trans>More than 100k business working with us, here some</Trans>
          </h2>
          <div className="flex justify-center items-center flex-col md:flex-row flex-wrap">
            {customers.map((customer, i) => (
              <img
                key={i}
                src={customer.logo}
                alt={customer.name}
                width={customer.width}
                className="max-w-full my-10 md:m-10"
              />
            ))}
          </div>
        </section>
      </div>
    </div>
  );
};

export default Index;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
